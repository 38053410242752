import { computed, ref } from 'vue'

import { formatYYYYMMDD } from '@/utils/Utility.js'
import { getDatesInRange, inferCampaignAggregateType } from '@/utils/rateConnectUtils.js'

const selectedPeriod = ref('last_30_days')
const selectedMarket = ref('all')
const selectedDisplayType = ref('table')
const selectedGraph = ref('clicks')
const selectedDateType = ref('bookingDate')
const appliedFiltersAmount = ref(0)
const selectedAggregation = ref('DAILY')
const selectedStatus = ref(['CONFIRMED', 'CANCELLED', 'COMPLETED'])
const selectedColumns = ref(null)
const loadedElementsOverviewTable = ref(6)
const endDate = computed(() => {
  let newEndDate = new Date()
  newEndDate.setDate(newEndDate.getDate() - 1)
  return newEndDate
})
const startDate = computed(() => {
  if (!endDate.value) return null
  let newStartDate = new Date()
  switch (selectedPeriod.value) {
    case 'last_14_days':
      newStartDate.setDate(endDate.value.getDate() - 14)
      break
    case 'current_month':
      newStartDate = new Date(endDate.value.getFullYear(), endDate.value.getMonth(), 1)
      break

    case 'last_30_days': {
      newStartDate.setDate(endDate.value.getDate() - 30)
      break
    }
    case 'last_3_months':
      newStartDate.setDate(endDate.value.getDate() - 90)
      break
    case 'last_6_months':
      newStartDate.setDate(endDate.value.getDate() - 180)
      break
    case 'last_12_months':
      newStartDate.setDate(endDate.value.getDate() - 365)
      break
    default:
      newStartDate.setDate(endDate.value.getDate() - 14)
      break
  }
  return newStartDate
})

export function useRateConnectPerformance() {
  const resetData = () => {
    selectedPeriod.value = 'last_30_days'
    selectedMarket.value = 'all'
    selectedGraph.value = 'clicks'
    selectedDisplayType.value = 'table'
    selectedAggregation.value = 'DAILY'
    selectedDateType.value = 'bookingDate'
    appliedFiltersAmount.value = 0
    selectedStatus.value = ['CONFIRMED', 'CANCELLED', 'COMPLETED']
  }

  const resetFilters = () => {
    selectedPeriod.value = 'last_30_days'
    selectedMarket.value = 'all'
    selectedGraph.value = 'clicks'
    selectedAggregation.value = 'DAILY'
    selectedDateType.value = 'bookingDate'
    selectedStatus.value = ['CONFIRMED', 'CANCELLED', 'COMPLETED']
    appliedFiltersAmount.value = 0
  }

  const yearlyStartDate = computed(() => {
    const yearlyStartDate = new Date()
    yearlyStartDate.setDate(endDate.value.getDate() - 365)
    return formatYYYYMMDD(yearlyStartDate, '-')
  })
  const formattedEndDate = computed(() => (endDate.value ? formatYYYYMMDD(endDate.value, '-') : ''))
  const formattedStartDate = computed(() => (startDate.value ? formatYYYYMMDD(startDate.value, '-') : ''))

  return {
    resetData,
    selectedDateType,
    resetFilters,
    selectedPeriod,
    selectedMarket,
    selectedGraph,
    startDate,
    yearlyStartDate,
    endDate,
    formattedStartDate,
    formattedEndDate,
    selectedDisplayType,
    selectedAggregation,
    appliedFiltersAmount,
    selectedStatus,
    selectedColumns,
    loadedElementsOverviewTable,
  }
}

export function usePrettyStats(statsData, lastUpdated, language) {
  function checkDate(date) {
    return new Date(date.startDate) <= new Date(lastUpdated?.value)
  }
  const DateTimeFormat = computed(() => {
    let options = {}
    switch (selectedAggregation.value) {
      case 'DAILY':
        options = {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        }
        break
      case 'WEEKLY':
        options = {
          day: '2-digit',
          month: 'short',
        }
        break
      case 'MONTHLY':
        options = {
          month: 'short',
          year: 'numeric',
        }
        break
      default:
      // do nothing
    }
    return new Intl.DateTimeFormat(language.value, options)
  })
  const campaignTypes = computed(() => {
    if (statsData.value.length === 0) {
      const fakeDates = getDatesInRange(startDate.value, endDate.value)
      return fakeDates.map(() => null)
    } else {
      return statsData.value?.map(campaign => inferCampaignAggregateType(campaign))
    }
  })
  const categories = computed(() => {
    if (!statsData.value || statsData.value.length === 0) {
      const fakeDates = getDatesInRange(startDate.value, endDate.value)
      return fakeDates.map(item => DateTimeFormat.value?.format(item))
    }
    return statsData.value.map(d => {
      // we always get startDate & endDate
      // but for showing the day aggregation we can pick either startDate or endDate
      if (selectedAggregation.value === 'DAILY' || selectedAggregation.value === 'MONTHLY') {
        let startDate = new Date(d.startDate)
        return DateTimeFormat.value?.format(startDate)
      } else {
        // week
        let startDate = new Date(d.startDate)
        let endDate = new Date(d.endDate)
        return DateTimeFormat.value?.format(startDate) + ' - ' + DateTimeFormat.value?.format(endDate)
      }
    })
  })
  const tickPositions = computed(() => {
    if (statsData.value.length === 0) {
      const fakeDates = getDatesInRange(startDate.value, endDate.value)

      return fakeDates.map((_, index) => {
        return index
      })
    } else {
      return statsData.value?.map((d, index) => {
        if (selectedAggregation.value === 'DAILY') {
          return new Date(d.startDate).getDay() === 1 ? index : undefined
        }
        return index
      })
    }
  })

  const bookings = computed(() => {
    const bookingsNames = ['partnerClicks', 'bookings', 'click2BookRatio', 'cancelledBookings']
    let bookingsObj = {}
    bookingsNames.forEach(name => {
      switch (name) {
        case 'click2BookRatio':
          bookingsObj[name] = statsData.value?.filter(checkDate).map(d => d[name] / 100)
          break
        default:
          bookingsObj[name] = statsData.value?.filter(checkDate).map(d => {
            return d[name] || 0
          })
      }
    })
    return bookingsObj
  })

  const visits = computed(() => {
    const visitsNames = ['totalImpressions', 'partnerClicks', 'cpc', 'clickThroughRate', 'unavailabilityScore']
    let visitsObj = {}
    visitsNames.forEach(name => {
      switch (name) {
        case 'clickThroughRate':
          visitsObj[name] = statsData.value?.filter(checkDate).map(d => d[name] / 100)

          break
        case 'cpc':
          visitsObj[name] = statsData.value?.filter(checkDate).map(d => (d[name] === null ? null : d[name] / 100))
          break
        case 'unavailabilityScore':
          visitsObj[name] = statsData.value?.filter(checkDate).map(d => (d[name] === null ? -0.1 : d[name]))
          break
        default:
          visitsObj[name] = statsData.value?.filter(checkDate).map(d => d[name])
      }
    })
    return visitsObj
  })

  const revenue = computed(() => {
    const revenueNames = ['bookings', 'bookingRevenue', 'avgBookingRevenue', 'bookingRefundedRevenue']
    let revenueObj = {}
    revenueNames.forEach(name => {
      switch (name) {
        case 'bookingRevenue':
        case 'avgBookingRevenue':
          revenueObj[name] = statsData.value?.filter(checkDate).map(d => d[name] / 100)
          break
        case 'bookingRefundedRevenue':
          revenueObj[name] = statsData.value?.filter(checkDate).map(d => (d[name] ? d[name] / 100 : 0))
          break
        default:
          revenueObj[name] = statsData.value?.filter(checkDate).map(d => d[name])
      }
    })
    return revenueObj
  })

  const cpa = computed(() => {
    const cpaNames = ['costs', 'bookingRevenue', 'cpa']
    let cpaObj = {}
    cpaNames.forEach(name => {
      switch (name) {
        case 'cpa':
        case 'costs':
        case 'bookingRevenue':
          cpaObj[name] = statsData.value?.filter(checkDate).map(d => Number(Math.round(d[name] / 100 + 'e2') + 'e-2'))
          break
        default:
          cpaObj[name] = statsData.value?.filter(checkDate).map(d => d[name])
      }
    })
    return cpaObj
  })

  const commissionCosts = computed(() => {
    const commissionCostNames = ['commissionCost', 'bookingRevenue', 'cpaLevel']
    const commissionCostsObj = {}
    commissionCostNames.forEach(name => {
      switch (name) {
        case 'commissionCost':
        case 'bookingRevenue':
          commissionCostsObj[name] = statsData.value?.filter(checkDate).map(d => d[name] / 100)
          break
        case 'cpaLevel':
          commissionCostsObj[name] = statsData.value?.filter(checkDate).map(d => d[name])
          break
        default:
          commissionCostsObj[name] = statsData.value?.filter(checkDate).map(d => d[name])
      }
    })
    return commissionCostsObj
  })

  return {
    bookings,
    visits,
    revenue,
    cpa,
    commissionCosts,
    campaignTypes,
    categories,
    tickPositions,
  }
}
