<template>
  <div
    class="tw-relative tw-rounded-xl tw-max-w-[380px] tw-px-6 tw-pt-9 tw-pb-6 tw-flex-1 tw-relative tw-w-full tw-mx-auto md:tw-mx-0"
    :class="
      type === 'paid'
        ? 'pro-card tw-bg-blue-100 tw-border-blue-700 tw-border'
        : 'base-card tw-border-gray-300 w-border-b'
    "
  >
    <div v-if="type === 'free'">
      <div class="tw-border-b tw-border-gray-200 tw-pb-6">
        <p class="tw-pb-2 tw-font-bold tw-text-xl">
          {{ title }}
        </p>
        <p class="tw-font-bold tw-mb-4">{{ $t('plus_product_page_basic') }}</p>
        <p class="tw-text-3xl tw-font-bold tw-mb-4">{{ $t('price_plan.price_headline.free') }}</p>
        <MainButton disabled class="tw-w-full">{{ $t('plus_product_page_current_plan') }}</MainButton>
      </div>

      <ul class="tw-flex tw-flex-col tw-gap-2 tw-px-1 tw-mt-6">
        <li v-for="(feature, i) in basicFeaturesList" :key="i" class="tw-flex tw-justify-start tw-items-center">
          <CheckMark class="tw-mr-4 tw-h-[20px] tw-w-[20px] tw-bg-green-700 tw-p-[4px] tw-rounded-full tw-fill-white" />
          <span>{{ $t(feature) }}</span>
        </li>
      </ul>
    </div>

    <div v-if="type !== 'free'">
      <div class="tw-border-b tw-border-gray-200 tw-pb-6">
        <p class="tw-pb-6 tw-font-bold tw-text-xl">
          <img class="tw-flex tw-items-center tw-h-[23px]" :src="businessPlusLogo" alt="Studio Plus logo" />
        </p>

        <p class="tw-text-3xl tw-font-bold tw-mb-2" v-if="!isPriceLoading">
          {{
            Intl.NumberFormat(locale, {
              style: 'currency',
              currency,
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            }).format(price / 100)
          }}
        </p>
        <p class="tw-text-3xl tw-font-bold tw-mb-2" v-else><RCInlineLoader color="#171717" /></p>
        <p class="tw-text-gray-700 tw-mb-4">{{ $t('check_out_billing_info_subscription') }}</p>
        <MainButton @click="redirectToPayment" class="tw-w-full">{{ $t('plus_product_page_cta') }}</MainButton>
      </div>

      <div class="tw-mt-6">
        <ul class="tw-flex tw-flex-col tw-gap-2 tw-mt-5 tw-px-1">
          <li v-for="(feature, i) in basicFeaturesList" :key="i" class="tw-flex tw-justify-start tw-items-center">
            <CheckMark
              class="tw-mr-4 tw-h-[20px] tw-w-[20px] tw-bg-green-700 tw-p-[4px] tw-rounded-full tw-fill-white"
            />
            <span>{{ $t(feature) }}</span>
          </li>
          <p class="tw-font-bold tw-mt-4 tw-mb-3">{{ $t('plus_product_page_benefits_header') }}</p>
          <li v-for="(plusFeature, i) in featuresList" :key="i" class="tw-flex tw-justify-start tw-items-center">
            <CheckMark
              class="tw-mr-4 tw-h-[20px] tw-w-[20px] tw-bg-green-700 tw-p-[4px] tw-rounded-full tw-fill-white"
            />
            <span class="tw-mr-6">{{ $t(plusFeature) }}</span>
          </li>
        </ul>
      </div>
      <div class="tw-mt-8">
        <small class="tw-text-gray-700 tw-text-sm">{{ `*${$t('business_studio_plus_tax_disclaimer')}` }}</small>
      </div>
    </div>
  </div>
</template>
<script setup>
import CheckMark from '@/components/icons/CheckMark.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'

const businessPlusLogo = new URL('/src/assets/img/logos/business-plus-logo.svg', import.meta.url)

const emit = defineEmits(['select'])
const redirectToPayment = () => emit('select')

defineProps({
  type: String,
  featuresList: Array,
  basicFeaturesList: Array,
  title: String,
  planName: String,
  price: Number,
  currency: String,
  isAdmin: Boolean,
  isPriceLoading: Boolean,
})
</script>
<style scoped>
.base-card {
  box-shadow:
    0 2px 4px 0 rgba(63, 87, 189, 0.1),
    0 2px 10px 0 rgba(108, 112, 122, 0.15);
}

.pro-card {
  box-shadow: 0px 4px 16px 0px #0000004d;
  margin-left: -12px;
  @media (max-width: theme('screens.md')) {
    @apply tw-mt-8 tw-ml-auto;
  }
}
</style>
