<template>
  <div class="tw-px-4 tw-mb-6" :key="selectedAccommodationId">
    <div class="tw-text-center tw-mt-14">
      <h1 class="tw-text-3.5xl tw-font-bold">{{ $t('rc_product_page_header') }}</h1>
      <p class="tw-text-gray-700 tw-text-xl tw-mt-4">{{ $t('rc_product_page_text') }}</p>
      <div v-if="isStartUpFetching || isPartnersFetching">
        <LaunchpadLoader class="tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-1" />
      </div>

      <div
        v-else-if="isEnabledForRateConnect && !isBlockedCountry && partnersOptions.length > 0"
        class="tw-mt-10 tw-flex tw-justify-center tw-items-center tw-flex-col md:tw-flex-row"
      >
        <!-- Only One Partner  -->
        <div
          v-if="partnersOptions.length === 1"
          class="tw-text-gray-800 tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-items-center"
        >
          <i18n-t
            tag="p"
            keypath="connectivity_provider_info"
            class="tw-flex tw-items-center tw-justify-center tw-flex-wrap md:tw-flex-nowrap"
          >
            <template #partnerName>
              <strong class="tw-ml-1 tw-mr-2">{{ selectedPartner?.displayText }}</strong>
              <UiTooltip
                tooltip-position="top"
                :label="$t('connectivity_provider_info_tooltip', { partnerName: selectedPartner?.displayText })"
                class="tw-cursor-pointer tw-flex"
              >
                <BaseIcon
                  icon-name="information-outline"
                  :height="16"
                  :width="16"
                  class="tw-fill-none tw-stroke-gray-700 tw-h-[16px] tw-w-[16px] tw-mr-1"
                >
                  <InformationOutline />
                </BaseIcon>
              </UiTooltip>
            </template>
          </i18n-t>
        </div>

        <!-- More Partners  -->
        <div
          v-if="partnersOptions.length > 1 && selectedPartner !== null"
          class="tw-text-gray-800 tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-items-center tw-mb-2 md:tw-mb-0"
        >
          <i18n-t
            tag="p"
            keypath="connectivity_provider_info"
            class="tw-flex tw-items-center tw-flex-wrap md:tw-flex-nowrap tw-justify-center"
          >
            <template #partnerName>
              <UiTooltip
                tooltip-position="top"
                :label="$t('connectivity_provider_info_tooltip', { partnerName: selectedPartner?.partnerName })"
                class="tw-cursor-pointer tw-flex tw-flex-col tw-mr-2"
              >
                <BaseIcon
                  icon-name="information-outline"
                  :height="16"
                  :width="16"
                  class="tw-fill-none tw-stroke-gray-700 tw-h-[16px] tw-w-[16px] tw-ml-1"
                >
                  <InformationOutline />
                </BaseIcon>
              </UiTooltip>
              <BaseSelect
                id="'select-connectivity-partner'"
                class="md:tw-min-w-[160px] tw-mr-1 tw-mt-2 md:tw-mt-0 tw-text-blue-600 tw-font-bold"
                :value="selectedPartner?.value"
                :options="partnersOptions"
                size="medium"
                display-text-key="displayText"
                @onChange="e => setSelectedPartner(e)"
              />
            </template>
          </i18n-t>
        </div>

        <MainButton
          class="md:tw-ml-6 tw-mt-4 md:tw-mt-0"
          :disabled="currentRole !== 'admin'"
          size="medium"
          @click="onCreateCampaignClick"
          >{{ $t('rc_product_page_cta') }}</MainButton
        >
      </div>

      <div v-else class="tw-mt-8">
        <MainButton @click="onReadMoreClick">{{ $t('tbs_home_carousel_1_cta') }}</MainButton>
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-mt-20">
      <div class="tw-m-auto tw-max-h-[125px] tw-h-full tw-mb-20">
        <img src="@/assets/img/rate-connect/rc-partners-selection.svg" alt="partners-selection" class="tw-w-full" />
      </div>
      <div class="tw-w-full tw-flex tw-flex-wrap tw-justify-between tw-gap-5 tw-px-8">
        <div v-for="item in infoItems" :key="item.id" class="select-partner-box tw-flex">
          <SelectPartnerInfoBox :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import MainButton from '@/components/base/buttons/MainButton.vue'
import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import SelectPartnerInfoBox from '@/components/rateConnect/setup/SelectPartnerInfoBox.vue'
import BaseSelect from '@/components/BaseSelect.vue'

import { useStartUp, usePartners } from '@/components/rateConnect/queries'
import { selectedAccommodationId, useAccommodationsByOrgId } from '@/layouts/queries'
import { RC_BLOCKED_COUNTRIES } from '@/constants/rcBlockedCountries.js'
import { useSetup } from '@/components/rateConnect/setup/useSetup'

const router = useRouter()
const store = useStore()

const infoItems = [
  { id: 1, header: 'rc_mp_benefit_header_2', text: 'rc_mp_benefit_text_2', icon: '' },
  { id: 2, header: 'rc_mp_benefit_header_1', text: 'rc_mp_benefit_text_1', icon: '' },
  { id: 3, header: 'rc_mp_benefit_header_3', text: 'rc_mp_benefit_text_3', icon: '' },
  { id: 4, header: 'rc_mp_benefit_header_4', text: 'rc_mp_benefit_text_4', icon: '' },
]

const { selectedPartner } = useSetup()
const currentRole = computed(() => store.state.session?.userRole)
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { isFetching: isStartUpFetching, hotelierData } = useStartUp(selectedAccommodationId)
const { isFetching: isPartnersFetching, filteredPartnersData } = usePartners(selectedAccommodationId)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)
const isEnabledForRateConnect = computed(() => hotelierData.value?.isEnabledForRateConnect)
const isBlockedCountry = computed(() => RC_BLOCKED_COUNTRIES.includes(currentAccommodation.value?.countryId))

const partnersOptions = computed(() => {
  return filteredPartnersData.value
    ?.filter(item => item.canNetCpa)
    .map(partnerItem => {
      return {
        value: partnerItem.partnerId,
        displayText: partnerItem.partnerName,
      }
    })
})

onMounted(() => {
  selectedPartner.value = null
  selectedPartner.value = partnersOptions.value?.[0]
})

watch(selectedAccommodationId, newSelectedAccommodationId => {
  if (newSelectedAccommodationId) {
    selectedPartner.value = null
  }
})

const setSelectedPartner = partnerId => {
  const findSelectedPartner = partnersOptions?.value.find(item => item.value === Number(partnerId))
  selectedPartner.value = findSelectedPartner
}

const onCreateCampaignClick = () => {
  router.push('/rate-connect/setup')
}

const onReadMoreClick = () => {
  router.push('/rate-connect/noteligible')
}
</script>
<style scoped>
.select-partner-box {
  flex-basis: calc(50% - 10px);
  @media (max-width: theme('screens.md')) {
    flex-basis: 100%;
  }
}
</style>
