<template>
  <div class="tw-bg-gray-100 tw-flex tw-w-full tw-py-[44px] md:tw-py-[68px]">
    <div
      class="tw-flex md:tw-flex-row tw-flex-col tw-mx-auto tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-px-4"
    >
      <div class="tw-w-full md:tw-w-1/2 lg:tw-pr-16">
        <div class="md:tw-max-w-[80%]">
          <div class="tw-max-w-[120px] tw-pb-9">
            <BaseIcon
              icon-name="trivago Business Studio"
              :height="23"
              :width="237"
              :viewbox-height="23"
              :viewbox-width="237"
              class="tw-inline-block"
            >
              <TrivagoLogoPlus />
            </BaseIcon>
          </div>

          <!-- Mobile Image -->
          <div class="tw-flex md:tw-hidden tw-mb-9">
            <img class="tw-m-auto tw-mx-auto tw-w-full tw-h-[208px]" :src="heroImage" alt="Studio Plus" />
          </div>

          <h3 class="tw-font-bold tw-text-2xl md:tw-text-3.5xl tw-mb-2" v-html="$t('plus_product_page_header')" />
          <div class="tw-text-xl tw-text-gray-700 tw-mb-8 tw-leading-[30px]">
            {{ $t('plus_product_page_subtext') }}
          </div>

          <div class="tw-mb-4 tw-flex tw-items-start tw-flex-col">
            <div class="tw-bg-red-200 tw-text-red-800 tw-py-1 tw-px-2 tw-rounded tw-text-sm">
              <i18n-t keypath="plus_product_page_price">
                <template #SubscriptionPrice
                  ><strong v-if="!isLoading">{{
                    Intl.NumberFormat(locale, {
                      style: 'currency',
                      currency,
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 0,
                    }).format(price / 100)
                  }}</strong>
                  <RCInlineLoader v-else color="#B4183A" />
                </template>
              </i18n-t>
            </div>
            <small class="tw-text-gray-700 tw-text-xs tw-mt-1">{{
              `*${$t('business_studio_plus_tax_disclaimer')}`
            }}</small>
          </div>
          <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-start tw-mt-4">
            <MainButton @click="upgradeSub" :disabled="!isAdmin" dataTestId="plus-hero-upgrade-btn">{{
              $t('plus_product_page_cta')
            }}</MainButton>
          </div>
        </div>
      </div>
      <!-- Web Image -->
      <div class="tw-hidden md:tw-flex md:tw-w-1/2 tw-w-full tw-relative tw-mx-auto">
        <img class="tw-m-auto tw-mx-auto tw-w-[315px] tw-mr-16" :src="heroImage" alt="Studio Plus" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import TrivagoLogoPlus from '@/components/icons/TrivagoLogoPlus.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'

import appAnalytics from '@/utils/tracking'
import { selectedAccommodationId } from '@/layouts/queries'

const emit = defineEmits(['upgradeSub'])
const upgradeSub = () => {
  appAnalytics.track(appAnalytics.events.SP_CLICK_UPGRADE_BANNER, {
    accommodationId: selectedAccommodationId.value,
  })
  emit('upgradeSub')
}

defineProps({
  price: Number,
  currency: String,
  isLoading: Boolean,
})

const heroImage = new URL(`@/assets/img/studio-plus/studio-plus-banner.svg`, import.meta.url)

const store = useStore()
const isAdmin = computed(() => store.state.session?.userRole === 'admin')
</script>
<style scoped>
.current-price :deep(span) {
  font-size: 32px;
  font-weight: bold;
}
.shadow {
  box-shadow: 0 2px 4px 0 rgba(63, 87, 189, 0.1), 0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
</style>
