<template>
  <div
    class="tw-max-w-screen-xxl tw-mx-auto tw-h-full md:tw-h-auto tw-mb-8 tw-flex md:tw-justify-between lg:tw-px-9 tw-items-center tw-gap-6"
  >
    <div
      class="tw-w-full lg:tw-w-1/2 tw-max-w-xl md:tw-block tw-flex tw-flex-col tw-justify-between md:tw-justify-center tw-h-full md:tw-h-auto"
    >
      <div class="tw-max-w-[120px] tw-py-4">
        <BaseIcon
          icon-name="trivago Business Studio"
          :height="23"
          :width="237"
          :viewbox-height="23"
          :viewbox-width="237"
          class="tw-inline-block"
        >
          <TrivagoLogoPlus />
        </BaseIcon>
      </div>
      <h3 class="tw-font-bold tw-text-2xl md:tw-text-3.5xl tw-mb-2" v-html="$t('plus_product_page_header')" />
      <div class="tw-text-xl tw-text-gray-700 tw-mb-4 tw-leading-[30px]">
        {{ $t('plus_product_page_subtext') }}
      </div>
      <div class="tw-mb-4 tw-flex tw-items-start tw-flex-col">
        <div class="tw-bg-red-200 tw-text-red-800 tw-py-1 tw-px-2 tw-rounded tw-text-sm">
          <i18n-t keypath="plus_product_page_price">
            <template #SubscriptionPrice
              ><strong v-if="!isPriceLoading">{{
                Intl.NumberFormat(locale, {
                  style: 'currency',
                  currency,
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 0,
                }).format(price / 100)
              }}</strong>
              <RCInlineLoader v-else color="#B4183A" />
            </template>
          </i18n-t>
        </div>
        <small class="tw-text-gray-700 tw-text-xs tw-mt-1">{{ `*${$t('business_studio_plus_tax_disclaimer')}` }}</small>
      </div>

      <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-start tw-mt-4">
        <MainButton @click="redirectToCompare">{{ $t('plus_product_page_cta') }}</MainButton>
      </div>
    </div>
    <div class="tw-hidden lg:tw-flex md:tw-w-1/2 tw-w-full tw-relative tw-mx-auto">
      <img class="tw-m-auto tw-mx-auto tw-w-[280px] tw-mr-16" :src="heroImage" alt="Studio Plus" />
    </div>
  </div>
  <div></div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useStudioPlusPrice } from '@/components/payment/queries'

import appAnalytics from '@/utils/tracking'
import MainButton from '@/components/base/buttons/MainButton.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import TrivagoLogoPlus from '@/components/icons/TrivagoLogoPlus.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'

import { selectedAccommodationId } from '@/layouts/queries'

const router = useRouter()
const store = useStore()

const currency = computed(() => store.state.currency || 'EUR')
const { studioPlusPrice, isLoading, isFetching } = useStudioPlusPrice({ selectedAccommodationId, currency })
const price = computed(() => studioPlusPrice.value?.baseSubscriptionMonthlyPrice ?? 0)
const isPriceLoading = computed(() => isLoading.value || isFetching.value)

const heroImage = new URL(`@/assets/img/studio-plus/studio-plus-banner.svg`, import.meta.url)

const redirectToCompare = () => {
  router.push({ name: 'studio-plus-compare' })
  appAnalytics.track(appAnalytics.events.SP_HOMEPAGE_BANNER_CLICKED, {
    item_id: selectedAccommodationId.value,
  })
}
</script>
<style scoped>
.current-price :deep(span) {
  font-size: 32px;
  font-weight: bold;
}
</style>
